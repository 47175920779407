//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import Countdown from "vuejs-countdown";
export default {
  data() {
    return {
      currentTime: "",
    };
  },

  methods: {
    countDownTimer() {
      let x = setInterval(() => {
        let wedding = new Date("February 22, 2022 10:00:00").getTime();
        let now = new Date().getTime();
        let cd = wedding - now;
        let days = Math.floor(cd / (1000 * 60 * 60 * 24));
        let hours = Math.floor((cd % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((cd % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((cd % (1000 * 60)) / 1000);
        this.currentTime = `${this.formatDigit(days)}d : ${this.formatDigit(
          hours
        )}h : ${this.formatDigit(minutes)}m : ${this.formatDigit(seconds)}s `;

        if (cd < 0) {
          clearInterval(x);
          this.currentTime = "Wedding Time";
        }
      }, 1000);
    },
    formatDigit(digit) {
      return +digit < 10 ? "0" + digit : digit;
    },
  },
  created() {
    this.countDownTimer();
  },
};

//
//
//
//
//
//
//
//
//
//
//
//

import HeroPage from "../components/HeroPage";
import VenuePage from "../components/VenuePage";
import GalleryPage from "../components/GalleryPage";
import GiftPage from "../components/GiftPage";
import FootPage from "../components/FootPage";
import OurStory from "../components/OurStory.vue";

export default {
  name: "Home",

  components: {
    HeroPage,
    VenuePage,
    GalleryPage,
    GiftPage,
    FootPage,
    OurStory,
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      slides: [
        {
          img: "23",
          text: `<h5><u>FAITH:</u></h5> We both attend the same fellowship. I have been with the fellowship since 2018 but we never really had any conversation.
<p>Fast forward to 2020, Emmanuel will come and meet me after fellowship and start asking UNENDING questions that I hardly had answers to, little did I know that Uncle was already tripping for this pretty Abba’s damsel.</p>
          <p>Long story short, I was attracted to how he displayed a good understanding of God’s word while leading prayers during a prayer meeting. So I humbly asked to be his bible study partner, he agreed and we became good friends.</p>

          
          <h5><u>EMMANUEL:</u></h5> So I first saw her in a midweek service at our fellowship and I was like “Who is this pretty Hausa babe?” but she was so quiet and I didn’t really think she was friendly.
<p>In 2020 whilst scrolling through my WhatsApp status, I saw a playful video of her on a mutual friends feed. I was surprised that she could be playful.</p>
<p>In that season I was single and searching for a partner. My heart always went back to her and I also felt the Lord was speaking to me about her in various ways (story for another day).</p>
<p>So how do I confirm that I was hearing right? I would hover around her like a bee and try to start a conversation with her, I found it to be quite herculean as she would always answer me in mono-syllables.</p> 
<p>Just as I was about to throw in the towel, GOD WAS FIGHTING MY BATTLE, she walked up to me and started a conversation and ever since then we became good friends.</p>

          `,
          title: "- HOW WE MET -",
          date: "23 - 02 - 2019",
        },
        {
          img: "25",
          text: `<h5><u>FAITH:</u></h5> Emmanuel and I started with bible study over the phone, He was so committed. I was amazed at his zeal. I felt a bit unserious in comparison with the way he was committed to it. I didn’t know he was catching feelings hehehe.<br>
          <p>One day, he called me and asked if he could take me out to get dinner. I was like “Is he just being nice or is it what I am thinking???  I accepted his offer and assumed it was for a bible study. When we got there, after ordering food, I took out my bible and notepad but He pushed it aside and started conversing with me on a personal note.</p>

          
          <h5><u>EMMANUEL:</u></h5> After I agreed to do bible study, I took it very seriously in a bid to make my calling and election sure (P.s If you don’t gerrit forget abourrit :D). 
      <p><br />I decided to shoot my shot early, so I asked her out on a date and she agreed. I thought she would get the message but she didn’t. On getting to the venue, she brought out her bible and note. At this point, I told her to hold on with the bible STORY as I was interested in HER story and the rest is HIStory. This is OUR story. LOL</p>
          `,
          title: "- FIRST DATE -",
          date: "23 - 02 - 2019",
        },
        {
          img: "24",
          text: `<h5><u>FAITH:</u></h5> My friend Lola had got me a fashion ring as a gift, unknown to me this was a deliberate move because Emmanuel had secretly asked her to help in finding out my ring size. She had also told me that she wanted to have a hangout with friends and would like me to invite Emmanuel. This was a regular hangout for friends in my mind oh. On getting to the venue, we all had fun, cracked jokes and were just generally enjoying the outing. Then In the most surprising way and at the most unexpected time and place in the venue, Emmanuel popped the question. I sincerely didn’t suspect a thing! I was truly surprised. I boldly said “YES!” to the love of my life.
          <p><br /> P.s Prior to all this, he had initially planned a birthday surprise for me but it flopped woefully (Story for another day).</p>

          
          <h5></u>EMMANUEL:</u></h5> <p>I was determined not to let this proposal flop again. Because of this, I called her friend, Lola, to organise a hangout so she wouldn’t suspect a thing.  I also asked her to help me find out her ring size, in furtherance of my mission :D . Fast forward to the hangout, we invited friends and everyone was in on it except HER, obviously. I was looking for the perfect time to propose but I was so shy so I kept holding on. Eventually, my eyes were opened to the perfect opportunity- a private place where it will be just the two of us and friends, I summoned up courage and proposed. I can’t even remember what I said sef, I even put the ring on the wrong hand LOL, but she said yes. GRACE covered me.</p>
          `,
          title: "- PROPOSAL -",
          date: "23 - 02 - 2019",
        },
        {
          img: "6",
          text: `<h5><u>FAITH & EMMANUEL:</u></h5> We are so happy to have found each other. We are excited about our future together. We are learning new ways to love each other daily. We are also so excited about our wedding day 22.02.22.`,
          title: "- NOW & FOREVER -",
          date: " 23 - 02 - 2019",
        },
      ],
    };
  },
};

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#007BFF",
        secondary: "#c3c7c7",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#0e2b29",
        warning: "#FFC107",
        gold: "#e08d10",
      },
    },
  },
});

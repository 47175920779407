//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      gallerys: [
        { img: "6.jpg" },
        { img: "1.jpg" },
        { img: "8.jpg" },
        { img: "3.jpg" },
        { img: "12.jpg" },
        { img: "14.jpg" },
        { img: "4.jpg" },
        { img: "16.jpg" },
      ],
    };
  },
};
